export default function formatPrice({
    value,
    taxRate = 0,
    purchaseUnit = 1,
    referenceUnit = 1,
    locale = 'de-DE',
    currencyId = 'EUR',
}: {
    value: number;
    taxRate?: number
    purchaseUnit?: number;
    referenceUnit?: number;
    locale?: string;
    currencyId?: string;
}) {
    const price = Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyId,
    }).format((isNaN(value) ? 0 : ((value / purchaseUnit)) * (referenceUnit ?? 1)) * (1 + (taxRate/100)));

    return price;
}
