import type { BlockProps } from '.';

import Text from '@components/molecules/block/renderer/text';

const Block = ({ type, slots, wrapContent = true }: BlockProps) => {

    const renderBlock = () => {
        switch (type) {
            case 'text':
                return <Text slots={slots} wrapContent={wrapContent} />


            default:
                console.log(`Renderer not found: ${type}`);
                return null;
        }
    }

    return renderBlock()
}

export default Block
