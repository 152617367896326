'use client'

import { fetchBonusItems, handleRemoveItemsFromCart, fetchCart } from "@app/actions"

import { useSession } from "next-auth/react"
import { createContext, useContext, useEffect, useState } from "react"

const CartProviderContext = createContext<any>(undefined)

type Cart = any
type BonusItem = { id: string, amount: number, startDate?: string, endDate?: string }

export function useCartContext() {
    return useContext(CartProviderContext)
}

const CartProvider = ({ children }: { children: React.ReactNode }) => {
    const { data: session } = useSession()

    let [loading, setLoading] = useState<boolean>(false)

    let [cart, setCart] = useState<Cart>()

    let [bonusItems, setBonusItems] = useState<BonusItem[]>()
    let [showOffcanvas, setShowOffcanvas] = useState(false);

    useEffect(() => {
        if (undefined === bonusItems) {
            const fetch = async () => await fetchBonusItems().then((items: any[]) => {
                setBonusItems(items)
            })
            fetch()
        }
    }, [bonusItems])


    useEffect(() => {
        setLoading(true)
        const fetch = async () => await fetchCart().then(cart => {
            setCart(cart)
            setLoading(false)
        })
        fetch()
    }, [session?.user?.id])


    useEffect(() => {
        let removableBonusItems: string[] = []
        cart?.lineItems?.forEach(async (lineItem: any) => {
            const item = findBonusItem(lineItem.referencedId)
            if (item && !isBonusItemAvailable(item)) {
                removableBonusItems.push(lineItem.referencedId)
            }
        })

        if (0 < removableBonusItems.length) {
            const remove = async () => {
                await handleRemoveItemsFromCart(removableBonusItems).then(cart => {
                    setCart(cart)
                })
            }
            remove()
        }
    }, [cart])


    const findBonusItem = (bonusItemId: string) => bonusItems?.find(({ id }) => bonusItemId === id)

    const isBonusItem = (productId: string) => -1 !== bonusItems?.findIndex(({ id }) => id === productId)
    const isBonusItemAvailable = ({ amount, startDate, endDate }: { id: string, amount: number, startDate?: string, endDate?: string }) => (
        undefined !== cart
        && amount <= cart?.price?.netPrice
        && (undefined === startDate || new Date() >= new Date(startDate))
        && (undefined === endDate || new Date() <= new Date(endDate))
    )

    return (
        <CartProviderContext.Provider value={{
            loading,
            setLoading,

            cart,
            setCart,

            bonusItems: bonusItems?.filter(({ id: bonusItemId, amount, startDate, endDate }) => (amount <= cart?.price?.netPrice && (undefined === startDate || new Date() >= new Date(startDate)) && (undefined === endDate || new Date() <= new Date(endDate)) && -1 === cart?.lineItems?.findIndex(({ id }: { id: string }) => bonusItemId === id))),
            setBonusItems,

            showOffcanvas,
            setShowOffcanvas,

            isBonusItemAvailable,
            isBonusItem,

            findBonusItem
        }}>
            {children}
        </CartProviderContext.Provider>
    )
}

export default CartProvider
