import React, { Fragment } from "react"

import Container from "react-bootstrap/Container"
import Content from "@components/atoms/content"

import dynamic from "next/dynamic";
const NoSsr = dynamic(() => import('@components/atoms/no-ssr'));

const Text = ({ slots, wrapContent }: any) => {

    const renderContent = () => (
        slots.map((element: any, index: number) => {
            const verticalAlign = element.fieldConfig?.find(({ name }: { name: string }) => 'verticalAlign' === name)
            const content = element.fieldConfig?.find(({ name }: { name: string }) => 'content' === name)

            const renderComponent = <Content key={'b' + index} html={content?.value ?? element.data.content} />

            return (
                <Fragment key={'b' + index}>{'flex-end' === verticalAlign?.value ? <NoSsr>{renderComponent}</NoSsr> : renderComponent}</Fragment>
            )
        })
    )

    return (true === wrapContent ? (
        <Container className={'py-2'} fluid>
            <Container>
                {renderContent()}
            </Container>
        </Container>
    ) : (
        renderContent()
    ))
}

export default Text
